import type { CountryCode } from 'libphonenumber-js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { z } from 'zod';

export const voyadoContactSchema = z
    .object({
        firstName: z.string().min(1, 'First name is required'),
        lastName: z.string().min(1, 'Last name is required'),
        email: z
            .string()
            .min(1, 'Email is required')
            .email('Invalid email address'),
        company: z.string().min(1, 'Company is required'),
        companyTitle: z.string().min(1, 'Company title is required'),
        street: z.string().optional(),
        zipCode: z.string().optional(),
        city: z.string().optional(),
        countryCode: z.string().length(2, 'Country is required'),
        mobilePhone: z.string().min(1, 'Phone number is required'),
    })
    .superRefine((data, ctx) => {
        const parsed = parsePhoneNumberFromString(
            data.mobilePhone,
            data.countryCode as CountryCode,
        );

        if (!parsed || !parsed.isValid()) {
            ctx.addIssue({
                path: ['mobilePhone'],
                code: z.ZodIssueCode.custom,
                message: 'Invalid phone number for selected country',
            });
        }
    })
    .transform((data) => {
        const parsed = parsePhoneNumberFromString(
            data.mobilePhone,
            data.countryCode as CountryCode,
        );

        return {
            ...data,
            mobilePhone: parsed?.format('E.164') ?? data.mobilePhone,
        };
    });

export type VoyadoContact = z.infer<typeof voyadoContactSchema>;
