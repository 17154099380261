import {
    type CountryCode,
    parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { useFormContext } from 'react-hook-form';
import type { Dictionary } from 'types';

import VoyadoFormInputField from './VoyadoFormInputField';
import type { VoyadoContact } from './VoyadoSignUpForm.schema';

export default function VoyadoFormPhoneNumberField({
    dictionary,
}: {
    dictionary: Dictionary;
}) {
    const { getValues, setValue } = useFormContext<VoyadoContact>();

    const handleBlur = () => {
        const { mobilePhone, countryCode } = getValues();
        const parsed = parsePhoneNumberFromString(
            mobilePhone,
            countryCode.toUpperCase() as CountryCode,
        );

        if (parsed && parsed.isValid()) {
            setValue('mobilePhone', parsed.format('E.164'), {
                shouldValidate: true,
                shouldDirty: true,
            });
        }
    };

    return (
        <VoyadoFormInputField
            type="tel"
            name="mobilePhone"
            label={
                dictionary.voyadoSignUpFormPhoneNumberLabel ?? 'Phone number'
            }
            required
            onBlur={handleBlur}
        />
    );
}
