'use client';

import type { SelectProps } from '@components/ui/Select/Select';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@components/ui/Select/Select';
import { useState } from 'react';
import type { Path } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import { getMarketConfigsSortedByLabel } from '@/config/market-configurations';

import Flag from '../Flag';
import type { VoyadoContact } from './VoyadoSignUpForm.schema';

type VoyadoCountrySelectProps = SelectProps & {
    name: Path<VoyadoContact>;
    label?: string;
};

export default function VoyadoCountrySelect({
    name,
    label,
    ...props
}: VoyadoCountrySelectProps) {
    const markets = getMarketConfigsSortedByLabel();

    const {
        register,
        formState: { errors },
        setValue,
        getValues,
    } = useFormContext<VoyadoContact>();
    const [open, setOpen] = useState(false);

    const error = errors[name];

    return (
        <Select
            defaultValue={getValues(name)}
            onOpenChange={setOpen}
            onValueChange={(value) => {
                setValue(name, value);
            }}
            open={open}
            {...register(name)}
            {...props}>
            <div className="flex flex-col gap-1 max-md:col-span-2">
                {label && (
                    <label htmlFor={name} className="text-sm">
                        {label}
                    </label>
                )}
                <SelectTrigger id="countryCode" open={open}>
                    <SelectValue placeholder={label ?? 'Select country'} />
                </SelectTrigger>
            </div>
            <SelectContent>
                {markets.map((market) => {
                    return (
                        <SelectItem key={market.code} value={market.code}>
                            <span className="flex grow content-between items-center gap-2">
                                <Flag
                                    code={market.code}
                                    className="h-4 w-4 flex-shrink-0 rounded-full border border-black object-cover"
                                />
                                <span className="flex items-center gap-1">
                                    {market.label}
                                </span>
                            </span>
                        </SelectItem>
                    );
                })}
            </SelectContent>
            {error?.message && (
                <p className="mt-1 text-sm text-red-500">{error?.message}</p>
            )}
        </Select>
    );
}
